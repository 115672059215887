@import url(https://fonts.googleapis.com/css2?family=Courgette&family=IBM+Plex+Mono&display=swap);
/* base styles for home page and navbar*/
* {
  margin: 0;
  font-family: "IBM Plex Mono";
  color: #333;
}

.navbar {
  padding: 20px;
  align-items: center;
  max-width: 800px;
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  margin: 0 auto;
}
.navbar .links {
  margin-left: auto;
}
.navbar .site-logo {
  color: #49b6ff;
  font-size: 50px;
  font-weight: bold;
  text-decoration: none;

  /* background: #53bce6 -webkit-gradient(linear, 100% 0, 0 0, from(#53bce6), color-stop(0.5, #acacac), to(#ffffff));
  background-position: -4rem top; /*50px*/
  /* background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation-name: shimmer;
  -webkit-animation-duration: 2.2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-background-size: 4rem 100%; 
   */
}
/* @keyframes shimmer {
  0% {
      background-position: -4rem top; 
  }
  70% {
      background-position: 12.5rem top; 
  }
  100% {
      background-position: 12.5rem top; 
  }
} */
.navbar .create-link {
  font-size: 30px;
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar .create-link:hover {
  color: #db35f1;
}
.content {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
}

/* post styles */
.home-page {
  flex-wrap: wrap;
  max-width: 800px;
}
.instruction-box {
  width: 25%;
  height: 300px;
  font-size: 15px;
  float: left;
  margin-top: 30px;
  border: 1px solid black;
  border-radius: 10px;
  text-align: center;
  line-height: 250px;
  background-color: #58bcff;
  box-shadow: 5px 5px #888888; 
}
.instruction-box p {
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  color:#ffffff;
  font-weight: bolder;
}
.all-posts-list {
  display: inline;
  float: right;
  width: 70%;
}
.post-view {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
}
.post-view:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.post-view .post-content {
  display: none;
}
.post-view .post-title-div {
  background-color: #8d35f1;
  box-shadow: 5px 10px #888888; 
  height: auto;
  margin-bottom: 20px;
}
.post-view h2 {
  font-size: 20px;
  color: #ffffff;
  height: 30px;
  display: inline;
}
.post-view h3 {
  font-size: 15px;
  color: #000000;
  display: inline;
}

/* create post form styles */
.write-post {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.write-post label {
  text-align: left;
  display: block;
}
.write-post h2 {
  font-size: 25px;
  color: #8d35f1;
  margin-bottom: 20px;
}
.write-post input, .write-post textarea, .write-post select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.write-post textarea {
  height: 150px;
}
.write-post button {
  background: #8d35f1;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

